import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { RiskToleranceService } from '../../../services/riskTolerance.service';
import { FinancialService } from '../../../services/financial.service';
import { TranslateService } from '@ngx-translate/core';
import { NumberPercentPipe } from '@wtw/toolkit';
import { FormBuilder } from '@angular/forms';
import { ScoreIndicatorEnum } from 'app/api/generated/ScoreIndicatorEnum';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';
import { create, all } from 'mathjs';
import { ModalService } from '@wtw/platform/services';
import { BaseUserRestrictionsComponent } from '@wtw/platform/components/sub-role/base-user-restrictions.component';
import { RISKTOLERANCECONSTANTS } from 'app/shared/constants';
@Component({
    selector: 'loss-threshold-analysis',
    templateUrl: `../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/analysis/analysis.component.html`,
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/analysis/analysis.component.scss']
})

export class AnalysisComponent extends BaseUserRestrictionsComponent implements OnInit, OnDestroy {

    get metricByPeriod() {
        return this.selectedMetric.customAnalysis ? this._metricByPeriod : this._suggesteMetricByPeriod;
    }

    @Input()
    selectedMetric: any;
    @Input()
    priority: any;

    @Output() close = new EventEmitter();
    metric: any;

    public priorities;
    public translationKey = 'RTC.RISKTOLERANCE.VIEWANALYSIS';
    public translation: any = {};

    public dataItems;

    public riskTolerancePropertyId: any;
    public riskBearingCapacityId: any;
    public metrics: any;
    public priorityMetrics: any;
    public metricToleranceValue: {} = {};
    public selectedPeerGroup = 'S&P 100 Index';
    public calculatedCustom: boolean;

    customThreshold: boolean;
    isDefaultPriority:boolean;
    metricValues: { min: any; max: any; };
    periodsHeader: {
        projection: any,
        historical: any,
    };
    mathJaxObject;
    model: any = {};
    form: any = {};
    content = '$$ \\int _x = {{ {0} }}$$';
    isChanged = true;
    public editPriority = false;
    public openEditPeer: boolean;
    public isCreditFlag: boolean;
    public customThresholdRestrictionGuid;
    public editDefaultPriorityRestrictionGuid;
    public editCustomPriorityRestrictionGuid;
    public customThresholdRestrictionClassList = {
        limited: [],
        readonly: []
    }
    public customThresholdRestrictionIdList = {
        limited: [],
        readonly: []
    }

    public defaultPriorityRestrictionIdList: {
        limited: {
            value: string,
            action?()
        }[],
        readonly: {
            value: string,
            action?()
        }[] ,
        noaccess: {
            value: string,
            action?()
        }[]         
    };
    public customPriorityRestrictionIdList: {
        limited: {
            value: string,
            action?()
        }[],
        readonly: {
            value: string,
            action?()
        }[] ,
        noaccess: {
            value: string,
            action?()
        }[]         
    };
    public myModalService: ModalService;
    private mathContent = '$$ \\int _x = {{ {0} }}$$';
    private mathLoading: any;
    private _metricByPeriod: any;
    private _suggesteMetricByPeriod: any;
    private currentAnalysis: any;
    private selectedPeriod = null;
    private math: any;
    

    constructor(private riskToleranceService: RiskToleranceService, private financialService: FinancialService,
        private translateService: TranslateService, private shortNumberPipe: ShortNumberFormatPipe, private modalService: ModalService,
        private percentPipe: NumberPercentPipe, private fb: FormBuilder) { 
            super();
            this.defaultPriorityRestrictionIdList = {
                limited: [],
                readonly: [],
                noaccess:[]
            };
            this.customPriorityRestrictionIdList = {
                limited: [],
                readonly: [],
                noaccess:[]
            };
        }

    ngOnInit() {
        const config = {};
        this.math = create(all, config);
        setTimeout(c => {
            this.riskToleranceService.showNavigation = false;
            this.riskToleranceService.scrollEnabled = false;
        }, 10);
        this.loadFinancialData();
        this.dataItems = this.translateService.instant('RTC.FINANCIALS.INPUTS');
        this.riskTolerancePropertyId = this.riskToleranceService.riskTolerance.riskToleranceProperty.priorityId;
        this.riskBearingCapacityId = this.riskToleranceService.riskTolerance.riskBearingCapacity.priorityId;
        this.loadPriorities();
        this.priorityMetrics = this.priority.metrics.map(c => this.metrics[c]);
        this.periodsHeader = {
            projection: this.financialService.projectionDates.map(c => c),
            historical: this.financialService.historicalDates.slice(0, 3 - this.financialService.projectionDates.length).map(c => c)
        };
        //
        this.isCreditFlag = this.riskToleranceService.getCreditFlagForMetricPriority(this.priority.id, this.selectedMetric.id);

        this.loadMetric(this.priorityMetrics.find(c => parseInt(c.id, 10) === parseInt(this.selectedMetric.id, 10)), true);
        this.riskToleranceService.prioritiesChanged.subscribe(_ => {
            this.loadPriorities();
        });
        this.selectThreshold(1);

        this.customThresholdRestrictionGuid = RISKTOLERANCECONSTANTS.CustomThresholdRestrictions.restrictionsGuid;
        this.customThresholdRestrictionClassList.readonly.push({
            value:'custom-threshold-restricted',
            disableChildren: true
        });
        this.customThresholdRestrictionClassList.limited = this.customThresholdRestrictionClassList.readonly;
        this.customThresholdRestrictionIdList.readonly = [{
            value: 'customThresholdTitle',
            action: () => {
                this.myModalService = this.modalService;
                this.showModal('customThresholdTitle');
            }
        }];
        
       
        this.editCustomPriorityRestrictionGuid= RISKTOLERANCECONSTANTS.EditCustomPriority.restrictionsGuid
        this.editDefaultPriorityRestrictionGuid= RISKTOLERANCECONSTANTS.EditDefaultPriorities.restrictionsGuid
        this.defaultPriorityRestrictionIdList.noaccess.push({
            value: 'btnUpdatePriorities',
            action: () => {
                this.myModalService = this.modalService;
                this.showModal('btnUpdatePriorities');
            }
        });
        this.customPriorityRestrictionIdList.noaccess.push({
            value: 'btnUpdatePriorities',
            action: () => {
                this.myModalService = this.modalService;
                this.showModal('btnUpdatePriorities');
            }
        });
        this.setPriority();
    }
setPriority(){
    if (!this.priority.isDefault) {
        this.isDefaultPriority=false;    
    }
    else{
        this.isDefaultPriority=true;  
    }
}
    ngOnDestroy() {
        setTimeout(c => {
            this.riskToleranceService.showNavigation = true;
            this.riskToleranceService.scrollEnabled = true;
        }, 10);
    }

    loadFinancialData(): any {
        this.financialService.LoadFinancial();
    }

    loadPriorities() {
        this.metrics = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.metrics.toDictionary('id')));
        Object.keys(this.metrics).forEach(c => this.metrics[c].id = c);
        this.priorities = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.priorities));
        this.removeDeletedPriorities();
        this.priorities.forEach(c => {
            c.min = c.summaryValues.low;
            c.max = c.summaryValues.high;
            c.median = c.summaryValues.suggested;
            c.metrics.forEach(d => {
                const values = this.riskToleranceService.getMetricPeriods(c.id, d)
                    .sort((a, b) => new Date(b.period).valueOf() - new Date(a.period).valueOf());
                values.forEach(e => {
                    if (e.riskTolerance !== null && e.riskTolerance !== undefined && e.riskTolerance >= 0 && !this.metricToleranceValue[`${c.id}_${d}`]) {
                        this.metricToleranceValue[`${c.id}_${d}`] = e.riskTolerance;
                    }
                });
            });
            c.noData = c.metrics.find(d => this.metricToleranceValue[`${c.id}_${d}`] !== undefined) ? false : true;
        });
        this.priorities.forEach(p => {
            p.metrics.forEach(mid => {
                this.metrics[mid].hasToleranceValue = this.metricToleranceValue[`${p.id}_${mid}`] !== undefined;
                this.metrics[mid].canEvaluate = this.metricCanEvaluate(this.metrics[mid]);
            });
        });
    }
    metricCanEvaluate(metric) {
        let metricFormula = metric.formula;
        const metricArray = this.riskToleranceService.tokenize(metricFormula, { operands: /{\d+}/ }, 'invalid');
        metricArray.forEach(c => {
            if (c.type === 'operands') {
                const dataItemId = c.token.replace('}', '').replace('{', '');
                metricFormula = metricFormula.split(c.token)
                    .join(this.getDataItemValue(dataItemId)?? 0);
            }
        });
        try {
            this.math.evaluate(metricFormula);
            return true;
        } catch (e) {
            return false;
        }
    }
    getDataItemValue(dataItemId: number): number {
        const data = this.riskToleranceService.financialStatement.projectionData
            .concat(this.riskToleranceService.financialStatement.historicalData)
            .filter(x => x.dataItemId === +dataItemId).sort((a, b) => {
                return new Date(b.periodEndDate).valueOf() - new Date(a.periodEndDate).valueOf();
            }).slice(0, 3).filter(x => x.dataItemValue);
        if (data.length > 0) {
            return data[0].dataItemValue;
        }
        return null;
    }
    load(priority): any {
        if (!priority.noData) {
            this.priority = priority;
            this.priorityMetrics = priority.metrics.map(c => this.metrics[c]);
            const selectedMetric = this.priorityMetrics.find(c => c.hasToleranceValue);
            this.loadMetric(selectedMetric);
            this.setPriority();
        }
    }

    loadMetric(metric, initialLoad = false) {
        const initialMetrics = this.selectedMetric;
        this.mathLoading = true;
        if ((this.selectedMetric !== metric || initialLoad) && metric.canEvaluate) {
            this.selectedMetric = metric;
            this.isCreditFlag = this.riskToleranceService.getCreditFlagForMetricPriority(this.priority.id, metric.id);
            this._metricByPeriod = this.riskToleranceService.getMetricPeriods(this.priority.id, metric.id).toDictionary('period');
            this._suggesteMetricByPeriod = this.riskToleranceService.getSuggesteMetricByPeriod(this.priority.id, metric.id).toDictionary('period');
            if (!this.mathLoading) {
                this.mathLoading = true;
                setTimeout(c => {
                    this.mathLoading = false;
                }, 150);
            }

            this.model = {};
            this.model.metricName = metric.label;
            const values = this.riskToleranceService.getMetricPeriods(this.priority.id, metric.id)
                .sort((a, b) => new Date(b.period).valueOf() - new Date(a.period).valueOf());
            values.forEach(d => {
                this.calculatedCustom = d.customAnalysis;
                if (d.currentMetric && !this.model.currentMetric) {
                    this.model.currentMetric = d.currentMetric;
                } else if (this.model.currentMetric && this.model.percentChanged === undefined) {
                    this.model.percentChanged = this.percentPipe.transform((this.model.currentMetric / d.currentMetric - 1), '1.0-2') + '%';
                }

                if (!this.model.threshold) {
                    this.model.threshold = this.shortNumberPipe.transform(d.riskTolerance);
                    this.model.targetMetric = this.shortNumberPipe.transform(d.targetMetric);
                    this.model.year = new Date(d.period).getFullYear();
                    this.model.favorable = this.translateService.instant(this.translationKey + '.' + (d.peerPercentile > 0.5 ? 'FAVORABLE' : 'UNFAVORABLE'));
                    this.model.metricDirection = this.translateService
                        .instant(this.translationKey + '.' + (this.selectedMetric.scoreIndicator === ScoreIndicatorEnum.higherIsBetter ? 'LOWER' : 'HIGHER'));
                    this.model.metricDirection = (String(this.model.metricDirection)).toLowerCase();
                    this.model.acceptableDeviation =
                        d.acceptableDeviation !== undefined ? this.percentPipe.transform(d.acceptableDeviation, '1.0-2') + '%' : '-';
                    const groups = this.riskToleranceService.peerRefData.peerGroups
                        .concat(this.riskToleranceService.peerRefData.globalPeerGroups);
                    this.model.peerGroup = groups.find(x => x.peerGroupId.toString()
                        === this.riskToleranceService.run.riskTolerance.selectedPeerGroup.toString());
                    if (this.model.peerGroup) {
                        this.model.peerGroup = this.model.peerGroup.peerGroupName;
                        this.selectedPeerGroup = this.model.peerGroup;
                    }
                } else {
                    if (this.model.threshold && this.model.percentChanged === undefined) {
                        this.model.percentChanged = this.percentPipe.transform((this.model.currentMetric / d.currentMetric - 1), '1.0-2') + '%';
                    }
                }
            });
            this.generateCustomAnalysis();
            if (initialMetrics !== metric && initialLoad === false) {
                this.selectThreshold(1);
            }
        }
    }

    loadFormula() {
        if (this.mathLoading) {
            this.mathLoading = false;
            this.loadMathConfig();
            Promise.resolve(null).then(_ => {
                this.renderMath();
            });
        }
        return false;
    }

    openPeerGroup() {
        this.openEditPeer = true;
    }

    closePeer() {
        this.loadPriorities();
        this.loadMetric(this.selectedMetric, true);
        this.openEditPeer = false;
    }

    openPrioritiesModal() {
        this.editPriority = true;
    }

    addPriority(priority) {
        this.editPriority = false;
        this.riskToleranceService.showNavigation = false;
        setTimeout(c => {
            this.riskToleranceService.scrollEnabled = false;
        }, 50);
        if (priority) {
            this.riskToleranceService.addOrUpdate(priority);
            this.riskToleranceService.saveRun(false);
            const selectedMetric = this.selectedMetric;
            this.loadPriorities();
            this.load(priority);
            if (priority.metrics.find(c => c === selectedMetric.id)) {
                this.loadMetric(selectedMetric);
            } else {
                this.loadMetric(this.metrics[this.priority.metrics[0]]);
            }
        } else {
            this.loadPriorities();
            this.loadMetric(this.selectedMetric);
        }
    }

    recalculate() {
        // this.riskToleranceService.riskTolerance.customAnalysis
        const rt = this.riskToleranceService.riskTolerance as any;
        rt.customAnalysis.forEach(c => c.selectedForCalculation = false);
        let currentAnalysis = rt.customAnalysis.find(c => c.metricId === +this.selectedMetric.id && c.priorityId === +this.priority.id);
        if (!currentAnalysis) {
            currentAnalysis = {};
            currentAnalysis.metricId = this.selectedMetric.id;
            currentAnalysis.priorityId = this.priority.id;
            rt.customAnalysis.push(currentAnalysis);
        }
        currentAnalysis.selectedForCalculation = true;
        currentAnalysis.periodAnalysis = this.form.getRawValue().customAnalysis;
        currentAnalysis.periodAnalysis.forEach(c => {
            if (c.acceptableDeviation.acceptableDeviation) {
                c.acceptableDeviation.acceptableDeviation /= 100;
            }
        });
        this.riskToleranceService.calltrigger('calc_custom_analysis').subscribe(c => {
            this.loadMetric(this.selectedMetric, true);
            this.selectThreshold(2);
        });
    }

    reset() {
        this.form.controls.customAnalysis.controls.forEach((c, i) => {
            c.controls.acceptableDeviation.controls.acceptableDeviation.patchValue(null, { emitEvent: false });
            c.controls.acceptableDeviation.controls.isEdit.patchValue(false, { emitEvent: false });
            c.controls.riskTolerance.controls.riskTolerance.patchValue(null, { emitEvent: false });
            c.controls.riskTolerance.controls.isEdit.patchValue(false, { emitEvent: false });
            c.controls.targetMetric.controls.targetMetric.patchValue(null, { emitEvent: false });
            c.controls.targetMetric.controls.isEdit.patchValue(false, { emitEvent: false });
        });
        this.currentAnalysis.forEach(c => {
            c.acceptableDeviation.acceptableDeviation = null;
            c.acceptableDeviation.isEdit = false;
            c.targetMetric.targetMetric = null;
            c.targetMetric.isEdit = false;
            c.riskTolerance.riskTolerance = null;
            c.riskTolerance.isEdit = false;
        });
        this.riskToleranceService.riskTolerance.customAnalysis = this.riskToleranceService.riskTolerance.customAnalysis.filter(x =>
            !(x.metricId === +this.selectedMetric.id && x.priorityId === +this.priority.id));
        this.riskToleranceService.saveRun(false);
        this.riskToleranceService.updateAllPrioritiesSummaryValue();
        this.customThreshold = true;
        this.calculatedCustom = false;
    }

    generateCustomAnalysis() {
        this.isChanged = false;
        const rt = this.riskToleranceService.riskTolerance as any;
        if (!rt.customAnalysis) {
            rt.customAnalysis = [];
        }
        this.currentAnalysis = rt.customAnalysis.find(c => c.metricId === +this.selectedMetric.id && c.priorityId === +this.priority.id);
        if (!this.currentAnalysis) {
            this.currentAnalysis = [...this.periodsHeader.projection, ...this.periodsHeader.historical].map((c, i) => {
                return {
                    period: c,
                    acceptableDeviation: {
                        acceptableDeviation: null,
                        isEdit: false
                    },
                    targetMetric: {
                        targetMetric: null,
                        isEdit: false
                    },
                    riskTolerance: {
                        riskTolerance: null,
                        isEdit: false
                    }
                };
            });
        } else {
            this.currentAnalysis = JSON.parse(JSON.stringify(this.currentAnalysis.periodAnalysis));
            this.currentAnalysis.forEach(c => {
                if (c.acceptableDeviation.acceptableDeviation) {
                    c.acceptableDeviation.acceptableDeviation *= 100;
                }
            });
        }
        this.form = Object.toFormGroup({ customAnalysis: this.currentAnalysis }, this.fb);
        this.form.controls.customAnalysis.controls.forEach((c, i) => {
            c.controls.riskTolerance.controls.riskTolerance.valueChanges.subscribe(d => {
                if (d && this.currentAnalysis[i].riskTolerance.riskTolerance !== d) {
                    this.isChanged = true;
                    c.controls.acceptableDeviation.controls.acceptableDeviation.patchValue(null, { emitEvent: false });
                    c.controls.acceptableDeviation.controls.isEdit.patchValue(false, { emitEvent: false });
                    c.controls.targetMetric.controls.targetMetric.patchValue(null, { emitEvent: false });
                    c.controls.targetMetric.controls.isEdit.patchValue(false, { emitEvent: false });
                    c.controls.riskTolerance.controls.isEdit.patchValue(true, { emitEvent: false });
                }
            });
            c.controls.acceptableDeviation.controls.acceptableDeviation.valueChanges.subscribe(d => {
                if (d && this.currentAnalysis[i].acceptableDeviation.acceptableDeviation !== d) {
                    this.isChanged = true;
                    c.controls.riskTolerance.controls.riskTolerance.patchValue(null, { emitEvent: false });
                    c.controls.riskTolerance.controls.isEdit.patchValue(false, { emitEvent: false });
                    c.controls.targetMetric.controls.targetMetric.patchValue(null, { emitEvent: false });
                    c.controls.targetMetric.controls.isEdit.patchValue(false, { emitEvent: false });
                    c.controls.acceptableDeviation.controls.isEdit.patchValue(true, { emitEvent: false });
                }
            });
            c.controls.targetMetric.controls.targetMetric.valueChanges.subscribe(d => {
                if (d && this.currentAnalysis[i].targetMetric.targetMetric !== d) {
                    this.isChanged = true;
                    c.controls.acceptableDeviation.controls.acceptableDeviation.patchValue(null, { emitEvent: false });
                    c.controls.acceptableDeviation.controls.isEdit.patchValue(false, { emitEvent: false });
                    c.controls.riskTolerance.controls.riskTolerance.patchValue(null, { emitEvent: false });
                    c.controls.riskTolerance.controls.isEdit.patchValue(false, { emitEvent: false });
                    c.controls.targetMetric.controls.isEdit.patchValue(true, { emitEvent: false });
                }
            });
        });
    }

    selectThreshold(tab) {
        if (tab === 1) {
            this.selectedPeriod = null;
            this.getSuggestedthreshold();
            this.customThreshold = false;
        } else {
            this.getCustomRecentValues();
            this.customThreshold = true;
        }
    }

    updateMathObj() {
        this.mathJaxObject = this.riskToleranceService.nativeGlobal()['MathJax'];
    }

    renderMath() {
        this.content = this.mathContent;
        let formula = this.selectedMetric.formula;
        formula = formula.replace(/\(/g, ' ({ ');
        formula = formula.replace(/\)/g, ' }) ');
        const re = /({\d*})/g;
        let m;

        do {
            m = re.exec(formula);
            if (m) {
                formula = formula.replace(m[1], `\\text{${this.dataItems['DATAITEM' + m[1].replace('{', '').replace('}', '')]}}`);
            }
        } while (m);
        this.content = this.content.replace('{0}', formula);
        this.content = this.content.replace(/\//g, ' \\over ');

        const angObj = this;
        setTimeout(() => {
            angObj.mathJaxObject.Hub.Queue(['Typeset', angObj.mathJaxObject.Hub, 'formula']);
        }, 100);
    }

    loadMathConfig() {
        this.updateMathObj();
        this.mathJaxObject.Hub.Config({
            showMathMenu: false,
            tex2jax: { inlineMath: [['$', '$']], displayMath: [['$$', '$$']] },
            // menuSettings: { zoom: 'Double-Click', zscale: '150%' },
            CommonHTML: { linebreaks: { automatic: true } },
            'HTML-CSS': { linebreaks: { automatic: true } },
            SVG: { linebreaks: { automatic: true } }
        });
    }

    closeAnalysis() {
        this.close.emit(this.selectedMetric);
    }

    onChange(event: Event) {
        this.selectedPeriod = event['target']['dataset']['sectionvalue'];
    }

    validateSelectedPeriod(period: string) {
        return this.selectedPeriod === null ? true : (this.selectedPeriod === period);
    }

    private removeDeletedPriorities() {
        if (Array.isArray(this.priorities)) {
            this.priorities = this.priorities.filter(x => !x.isDeleted);
        }
    }

    private getCustomRecentValues() {
        this.calculateMatricsRange('Custom');
        const recentDate = Object.keys(this._metricByPeriod).sort((a: any, b: any) => +new Date(b) - +new Date(a));
        // const rawSuggestedObj = this._metricByPeriod[recentDate[0]];
        const rawCustomObj = this.calculateRawCustom(recentDate);
        this.model.threshold = this.shortNumberPipe.transform(rawCustomObj.riskTolerance);
        this.model.year = new Date(rawCustomObj.year).getFullYear();
        this.model.targetMetric = this.shortNumberPipe.transform(rawCustomObj.targetMetric);
        this.model.acceptableDeviation = (rawCustomObj.acceptableDeviation !== undefined &&
            rawCustomObj.acceptableDeviation !== null) ?
            this.percentPipe.transform(rawCustomObj.acceptableDeviation, '1.0-2') + '%' : '-';
    }

    private getSuggestedthreshold() {
        this.calculateMatricsRange('Suggested');
        const recentDate = Object.keys(this._suggesteMetricByPeriod).sort((a: any, b: any) => +new Date(b) - +new Date(a));
        const rawSuggestedObj = this._suggesteMetricByPeriod[recentDate[0]];
        this.model.threshold = this.shortNumberPipe.transform(rawSuggestedObj.riskTolerance);
        this.model.year = new Date(recentDate[0]).getFullYear();
        this.model.targetMetric = this.shortNumberPipe.transform(rawSuggestedObj.targetMetric);
        this.model.acceptableDeviation = (rawSuggestedObj.acceptableDeviation !== undefined &&
            rawSuggestedObj.acceptableDeviation !== null) ?
            this.percentPipe.transform(rawSuggestedObj.acceptableDeviation, '1.0-2') + '%' : '-';
    }

    private calculateMatricsRange(thresholdType) {
        const metricPeriodValue = thresholdType === 'Suggested' ?
            this.riskToleranceService.getSuggesteMetricByPeriod(this.priority.id, this.selectedMetric.id) :
            this.riskToleranceService.getMetricPeriods(this.priority.id, this.selectedMetric.id);
        const filteredValues = metricPeriodValue.filter(c => c.riskTolerance !== null)
                .map(c => c.riskTolerance > 0 ? c.riskTolerance : c.riskTolerance * -1);
        this.metricValues = {
            min: filteredValues.length > 0 ? Math.min(...filteredValues) : 'N/A', // or any other default value
            max: filteredValues.length > 0 ? Math.max(...filteredValues) : 'N/A'  // or any other default value
        };
    }

    private calculateRawCustom(recentDate) {
        let rawCustomObj: any = {};
        for (let i = 0; i < recentDate.length; i++) {
            const customMetricData = this._metricByPeriod[recentDate[i]].riskTolerance;
            if (customMetricData !== null && customMetricData !== undefined) {
                rawCustomObj = this._metricByPeriod[recentDate[i]];
                rawCustomObj.year = recentDate[i];
                break;
            }
        }
        return rawCustomObj;
    }
}
