import { Injectable, Injector } from '@angular/core';
import { RunService, UserService } from '@wtw/platform/services';
import * as Dto from 'app/api/dtos'; 
import { FeaturePermissionType } from '@wtw/platform/models/FeaturePermissionType';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  public static currentRun: Dto.RunViewModel;
  static permissions: any;
  
  public static hasPermission(featureGuid: string): boolean {
    if (this.permissions) {
        return this.permissions[featureGuid][FeaturePermissionType.FullAccess].hasPermission;
    }
    return false;
}
public static checkPermissionType(featureGuid: string): number {
  if (this.permissions) {
      const permission = this.permissions[featureGuid];
      if (permission) {
          // Check for FullAccess
          if (permission[FeaturePermissionType.FullAccess]?.hasPermission) {
              return FeaturePermissionType.FullAccess;
          }
           // Check for Readonly
           if (permission[FeaturePermissionType.NoAccess]?.hasPermission) {
            return FeaturePermissionType.NoAccess;
        }
          // Check for Readonly
          if (permission[FeaturePermissionType.ReadOnly]?.hasPermission) {
              return FeaturePermissionType.ReadOnly;
          }
           // Check for Readonly
           if (permission[FeaturePermissionType.Limited]?.hasPermission) {
            return FeaturePermissionType.Limited;
        }
      }
  }
  // Return 0 or another default value if no permission matches
  return 0;
}

  private _runService: RunService;
  private _userService: UserService;

  constructor(private inj: Injector) {

   }

   public init() {
    setTimeout(() => {
      this._runService = this.inj.get(RunService);
      
      this._runService.activeRun.subscribe(c => {
          RoutesService.currentRun= c.data;
      });

      this._userService = this.inj.get(UserService);
      this._userService.userInfo.subscribe(c => {        
        RoutesService.permissions = c.details?.accessSettings.groupBy('featureGuid');
        if (RoutesService.permissions) {
          Object.keys(RoutesService.permissions).forEach(d => {
            RoutesService.permissions[d] = RoutesService.permissions[d].toDictionary('featurePermissionType');
          });
        }
      });
      
      this._runService.activeRunSubject$.resetPerformed.subscribe(c => {
        RoutesService.currentRun = null;
    });
  });
  }

  
  
}
