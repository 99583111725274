import { NpnSliderModule } from 'npn-slider';
import { Injector, NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PlatformRootModule } from "@wtw/platform";
import { RUN_LIST_NAVIGATION, RUN_PAGES, RUN_CLONE_SERVICE } from '@wtw/platform/bootstrap/extension-points';
import { SharedPipes } from 'app/pipes';
import { ClickOutsideModule } from 'ng-click-outside';
import { FileUploadModule } from 'ng2-file-upload';
import { storeFreeze } from 'ngrx-store-freeze';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { runviewmodelReducer } from './store/runviewmodel';
import * as Config from './config';
import { MainComponents } from './pages';
import * as Routing from './routes';
import { SharedComponents } from './shared';
import { environment } from 'environments/environment';
import { FinancialService } from './services/financial.service';
import { ScenarioProxy, ReferenceDataProxy, RiskToleranceProxy } from './api/dtos';
import { setAppInjector } from './services/app.injector';
import { CloneRunProxy } from './api/generated/CloneRunController';
import { RiskToleranceService } from './services/riskTolerance.service';
import { NumberFormatterPipe } from './pipes/number-formatter.pipe';
import { NumberPercentPipe } from '@wtw/toolkit';
import { ShortNumberFormatPipe } from './pipes/ShortNumberFormatExtenderPipe';
import { RoutesService } from './services/RoutesService';
import { DownloadService } from './services/download.service';
import { SubRoleFeatureGuard } from '@wtw/platform/services/guards/SubRoleFeatureGuard';
// eslint-disable-next-line max-len
export const metaReducers: MetaReducer<any>[] = !environment.production ? [storeFreeze] : [];

export function FinancialServiceFactory(provider: FinancialService) {
  return () => provider.init();
}

export function RiskToleranceServiceFactory(provider: RiskToleranceService) {
  return () => provider.init();
}

export function RoutesServiceFactory(provider: RoutesService) {
return () => provider.init();
}


export function DownloadServiceFactory(provider: DownloadService) {
  return () => provider.init();
  }

@NgModule({
  declarations: [...MainComponents, ...SharedComponents, ...SharedPipes
  ],
  imports: [
    PlatformRootModule.forRoot([
      { provide: RUN_PAGES, useValue: { routes: Routing.RUN_PAGES } },
      { provide: RUN_LIST_NAVIGATION, useValue: { runLinks: Config.RUN_LIST_NAVIGATION } },
      // { provide: PLATFORM_URL, useValue: { platformConfig: { platformUrl: UrlHelperService.getPlatformUrl() } } },
    ]),
    ReactiveFormsModule,
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    StoreModule.forRoot({ runviewmodel: runviewmodelReducer }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ClickOutsideModule,
    NpnSliderModule
  ],
  providers: [
    DownloadService,
    FinancialService,
    RiskToleranceService,    
    ScenarioProxy,
    CloneRunProxy,
    ReferenceDataProxy,
    RiskToleranceProxy,
    NumberFormatterPipe,
    NumberPercentPipe,
    ShortNumberFormatPipe,
    SubRoleFeatureGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: FinancialServiceFactory,
      deps: [FinancialService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: RiskToleranceServiceFactory,
      deps: [RiskToleranceService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: RoutesServiceFactory,
      deps: [RoutesService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: DownloadServiceFactory,
      deps: [DownloadService],
      multi: true
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add CUSTOM_ELEMENTS_SCHEMA
})export class CommonModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
