
import { Component, OnInit } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { RunService, ModalService } from '@wtw/platform/services';

import * as Highcharts from 'highcharts';
import { FinancingHierarchyModalComponent } from './financingHierarchyModal/financingHierarchyModal.component';
import { ReactiveComponent } from '@wtw/toolkit/src/utils/base.component';
import { BaseUserRestrictionsComponent } from '@wtw/platform/components/sub-role/base-user-restrictions.component';
import { RISKTOLERANCECONSTANTS } from 'app/shared/constants';

const More = require('highcharts/highcharts-more');
More(Highcharts);

@Component({
    selector: 'loss-threshold',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/lossThresholds.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/lossThresholds/lossThresholds.component.scss'],
})

export class LossThresholdsComponent extends BaseUserRestrictionsComponent implements OnInit {
    public translationKey = 'RTC.RISKTOLERANCE.LOSSTHRESHOLDS';
    openEditPeer: boolean;
    selectedPeer: any;
    public pageSelected = 0;
    public selectedPriority = 0;
    public translation: any = {
    };
    refreshGraph: any;
    public editFinanHierarchyRestrictionGuid;
    public editFinanHierarchyList = {
        limited:[],
        readonly:[],
        noaccess:[]
    }

    constructor(private rtService: RiskToleranceService, private runService: RunService, private modalService: ModalService) {
        // comment for lint to pass
        super();
    }


    ngOnInit() {
        this._subscriptions.push(this.runService.activeRun.subscribe(c => {
            const groups = this.rtService.peerRefData.peerGroups.concat(this.rtService.peerRefData.globalPeerGroups);
            this.selectedPeer = groups.find(x => x.peerGroupId.toString()
                === this.rtService.run.riskTolerance.selectedPeerGroup.toString());
            this.selectedPeer.numberOfPeers = this.calculatTotaleNumberOfCompanies(this.selectedPeer, c.data.riskTolerance.customPeerGroup);
        })
        );

        this.editFinanHierarchyRestrictionGuid = RISKTOLERANCECONSTANTS.EditFinancingHierarchy.restrictionsGuid;
        this.editFinanHierarchyList.noaccess.push({
            value:`btnEditFinanHierarchy`,
            action:()=>{
                this.myModalService= this.modalService;
                this.showModal(`btnEditFinanHierarchy`);
            }          
         }  ,
        )
    }
    openPeerGroup() {
        this.rtService.showNavigation = false;
        this.rtService.scrollEnabled = false;
        this.openEditPeer = true;
    }

    closePeer() {
        this.rtService.showNavigation = true;
        this.rtService.scrollEnabled = true;
        this.openEditPeer = false;
        this.refreshGraph = { name: 1 };
    }

    selectPriority(priority) {
        this.selectedPriority = priority;
        this.pageSelected = 1;
    }

    pageChanged(pageNumber) {
        this.pageSelected = pageNumber;
    }

    openFinancingHierarchy() {
        const confirmConfig = {
            class: 'modal-dialog modal-lg',
        };
        this.modalService.confirmWithComponent(FinancingHierarchyModalComponent, null, confirmConfig).then((res) => {
            if (res.result === true) {
                this.rtService.calltrigger('calc_peer_analysis').subscribe(c => {
                    this.rtService.calltrigger('calc_custom_analysis');
                });
            }
        });
    }

    calculatTotaleNumberOfCompanies (selectedPeerGroup, customPeerGroup) {
        let customPeerGroupCountryCount = 0;
        if (customPeerGroup !== null && customPeerGroup !== undefined) {
            customPeerGroupCountryCount = customPeerGroup.peers.length > 0 ? customPeerGroup.peers.length : 0;
        }
        return selectedPeerGroup !== null && selectedPeerGroup !== undefined && selectedPeerGroup.numberOfCompanies !== null &&
        selectedPeerGroup.numberOfCompanies !== undefined ?
        Number(selectedPeerGroup.numberOfCompanies) + Number(customPeerGroupCountryCount) : Number(customPeerGroupCountryCount);
    }
}
