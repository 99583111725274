import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { rest } from 'lodash';
//import { RxwebValidators } from '@rxweb/reactive-form-validators';

export abstract class RISKTOLERANCECONSTANTS {

    public static readonly RiskToleranceComponent = {
        restrictionsGuid: '6f8684e8-5dbd-4ec7-9148-0c891eebb6e7',
        identifier: 'RiskTolerance'
    };

    public static readonly FinancialComponent = {
        restrictionsGuid: '799d2c9d-94e2-4103-b8ed-8dcf990830b7',
        identifier: 'Financial'
    };

    public static readonly FinancialComponentChangeDetails={
        restrictionsGuid:'80ab6640-3aae-4d4e-bac5-e095cfea6947',
        identifier:'ChangeDetails'
    }

    public static readonly FinancialSubsectionRestrictions = {
        restrictionsGuid: '30fdc555-9a27-4524-8917-3a72eb8dbbc5',
        identifier: 'FinancialTabs'
    }

    public static readonly SaveFinancialStatements = {
        restrictionsGuid: '828a03fd-880f-441f-b414-e71ab337ec78',
        identifier: 'SaveFinancialStatements'
    };
  
    public static readonly EditFinancingHierarchy = {
        restrictionsGuid: 'cf9d4570-948e-4373-a66f-7872265fb30e',
        identifier: 'EditFinancingHierarchy'
    };

    public static readonly EditPriorities = {
        restrictionsGuid: '44a90d4c-8d1f-4d28-a767-47c623e7a798',
        identifier: 'EditPriorities'
    };

    public static readonly EditMetrics= {
        restrictionsGuid: 'acfe24ab-ef8b-4f60-b1db-921b7f02b35b',
        identifier: 'EditMetrics'
    }

    public static readonly CustomThresholdRestrictions = {
        restrictionsGuid: '1f3fde83-3335-418f-b771-2b9d663488cc',
        identifier: 'CustomThreshold'
    }

    public static readonly ViewFinancialStatementRestrictions = {
        restrictionsGuid: '431d5622-573a-4170-9d90-a9685881c9ab',
        identifier: 'ViewFinancialStatementRestrictions'
    }

    public static readonly AddProjections = {
        restrictionsGuid: 'bc622a9c-3fa0-4971-a217-16ad22d68dd6',
        identifier: 'AddProjections'
    }

    public static readonly EditDefaultPriorities = {
        restrictionsGuid: '7639fa25-e6e2-467e-853d-64e2be2f208f',
        identifier: 'EditDefaultPriorities'
    }

    public static readonly AddCustomPriority = {
        restrictionsGuid: '17948477-815b-4bb1-926c-51e2daa4e861',
        identifier: 'AddCustomPriority'
    }

    public static readonly AddCustomMetrics = {
        restrictionsGuid: '21026bd5-9531-41f6-8295-c06db318aeec',
        identifier: 'AddCustomMetrics'
    }

    public static readonly EditBuiltinMetrics = {
        restrictionsGuid: 'b158791e-2c3b-4aa0-829a-c378a82d7736',
        identifier: 'EditBuiltinMetrics'
    }

    public static readonly EditCustomPriority = {
        restrictionsGuid: '35ecbe0d-de45-4507-9797-5a501d30d782',
        identifier: 'EditCustomPriority'
    }

    public static readonly RemovePriority = {
        restrictionsGuid: '7566490e-ddd7-4f53-84ce-dcb479e67894',
        identifier: 'RemovePriority'
    }

    public static readonly RemoveCustomMetrics = {
        restrictionsGuid: 'ed89d7e5-a237-4702-b171-e35acd0bfd80',
        identifier: 'RemoveCustomMetrics'
    }

    public static readonly EditCustomMetrics = {
        restrictionsGuid: '110e7d65-b7c1-4ed6-a162-156b711d1ba1',
        identifier: 'EditCustomMetrics'
    }
}