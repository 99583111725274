import { Component, Input, Output, EventEmitter } from "@angular/core";
import { BaseUserRestrictionsComponent } from "@wtw/platform/components/sub-role/base-user-restrictions.component";
import { RISKTOLERANCECONSTANTS } from "app/shared/constants";
import { ModalService } from "@wtw/platform/services";
import { OnInit } from "@angular/core";

interface IdList {
  limited: { value: string; action?(): void }[];
    readonly: { value: string; action?(): void }[]; 
    noaccess: { value: string, action?(): void }[]
}

@Component({
  selector: "app-tabs-extra-menu",
  templateUrl:
    "../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/tabsExtraMenu/tabsExtraMenu.component.html",
  styleUrls: [
    "../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/tabsExtraMenu/tabsExtraMenu.component.scss",
  ],
})
export class TabsExtraMenuComponent
  extends BaseUserRestrictionsComponent
  implements OnInit
{
  @Output() itemClicked: EventEmitter<string> = new EventEmitter<string>();
  @Input() items: any;
  @Input() priority: any;
  @Input() parentComponentName?: string;
  @Input() isCustomMetric: boolean;

  public showDetails = false;
  public restrictionsGuid: string;
  public restrictionsCustomMetGuild: string;
  public restrictionsEBuiltGuid: string;
  public restrictionsCustPGuid: string; 
  public restrictionsRemovePGuid: string;   
  public isDropDownDisabled: boolean = true;
  public idList: IdList = { limited: [], readonly: [] , noaccess : []};
  public idListCustom: IdList = { limited: [], readonly: [], noaccess: [] };
  public disabledIdList: IdList = { limited: [], readonly: [], noaccess: [] };
  public disabledIdCustPList: IdList = { limited: [], readonly: [], noaccess: [] };
  public idListBuildMetric: IdList = { limited: [], readonly: [], noaccess: [] };

  public applyUserRestrictions: boolean;
  constructor(private modalService: ModalService) {
    super();
  }

  ngOnInit() {
    this.setUpRestrictions();
  }

  setUpRestrictions() {
    if (this.parentComponentName) {
      switch (this.parentComponentName) {
        case "RTC.RISKTOLERANCE.DEFINEPRIORITIES":
          this.setupUserRestrictionsForDefinePrioritiesPage();
          this.applyUserRestrictions = true;
          break;
        case "RTC.RISKTOLERANCE.DEFINEPRIORITIESMODAL":
          this.setupUserRestrictionsForPrioritiesModalPage();
          this.applyUserRestrictions = true;
          break;
      }
    }
  }

  setupUserRestrictionsForPrioritiesModalPage() {
    this.items.forEach((item, index) => {
      if (item.key === "edit") {
        if (this.isCustomMetric) {
          this.addItemToList(this.idList.readonly, item, index);
        } else {
          this.addItemToList(this.idListBuildMetric.readonly, item, index);
        }
      } else {
        this.addItemToList(this.idListCustom.readonly, item, index);
      }
    });

    this.idList.limited = this.idList.readonly;
    this.idListCustom.limited = this.idListCustom.readonly;
    this.idListBuildMetric.noaccess = this.idListBuildMetric.readonly;
    this.restrictionsGuid =
      RISKTOLERANCECONSTANTS.EditCustomMetrics.restrictionsGuid;
    this.restrictionsCustomMetGuild =
      RISKTOLERANCECONSTANTS.RemoveCustomMetrics.restrictionsGuid;
      this.restrictionsEBuiltGuid =
      RISKTOLERANCECONSTANTS.EditBuiltinMetrics.restrictionsGuid;
  }

  setupUserRestrictionsForDefinePrioritiesPage() {
    this.items.forEach(item => {
        const action = () => {
            this.myModalService = this.modalService;
            this.showModal(item.displayName + this.priority.id);
        };
    
        const listItem = {
            value: item.displayName + this.priority.id,
            action: action
        };
    
        if (!this.priority.isDefault) {
            if (item.key === 'edit') {
                this.disabledIdCustPList.noaccess.push(listItem);
            } else {
                this.disabledIdList.readonly.push(listItem);
            }
        } else {
            if (item.key === 'remove') {
                this.disabledIdList.readonly.push(listItem);
            } else {
                this.idList.noaccess.push(listItem);
            }
        }
    });

    this.restrictionsGuid = RISKTOLERANCECONSTANTS.EditDefaultPriorities.restrictionsGuid;
    this.restrictionsCustPGuid = RISKTOLERANCECONSTANTS.EditCustomPriority.restrictionsGuid;   
    this.disabledIdList.noaccess = this.disabledIdList.readonly;
    this.restrictionsRemovePGuid = RISKTOLERANCECONSTANTS.RemovePriority.restrictionsGuid;   
  }

  clicked(item) {
    event.stopPropagation();
    this.showDetails = false;
    this.itemClicked.emit(item);
  }

  openMenu() {
    if (
      this.parentComponentName &&
      (this.parentComponentName === "RTC.RISKTOLERANCE.DEFINEPRIORITIESMODAL" ||
        this.parentComponentName === "RTC.RISKTOLERANCE.DEFINEPRIORITIES")
    ) {
      setTimeout(() => {
        this.isDropDownDisabled = false;
      }, 500);
    } else {
      this.isDropDownDisabled = false;
    }
    this.showDetails = !this.showDetails;
  }

  clear() {
    this.showDetails = false;
    this.isDropDownDisabled = true;
  }

  private addItemToList(
    list: { value: string; action?(): void }[],
    item: any,
    index: number
  ) {
    list.push({
      value: item.displayName + index,
      action: () => {
        this.myModalService = this.modalService;
        this.showModal(item.displayName + index);
      },
    });
  }
}
