
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { create, all } from 'mathjs';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { MetricVm } from 'app/api/dtos';
import { ModalService } from '@wtw/platform/services';
import { PercentilesComponent } from '../percentiles/percentiles.component';
import { RISKTOLERANCECONSTANTS } from 'app/shared/constants';
import { BaseUserRestrictionsComponent } from '@wtw/platform/components/sub-role/base-user-restrictions.component';

@Component({
    selector: 'metrics-modal',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/metricsModal/metricsModal.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/definePriorities/metricsModal/metricsModal.component.scss']
})

export class MetricsModalComponent extends BaseUserRestrictionsComponent implements OnInit {
    @Input() metric: MetricVm;
    public translationKey = 'RTC.RISKTOLERANCE.DEFINEPRIORITIES.METRICMODAL';

    public translation: any = {
    };

    public prioritySelectOpt: any = [];
    public priorities: any;
    public metrics: any;
    public prioritiesArray: any;
    public defaultMetrics: any;
    public customMetrics: any;
    public model = {
        metricName: '',
        details: '',
        metricFormula: '',
        parsedMetricFormula: '',
        consequence: '',
        scoreIndicator: null,
        recentlyAdded: null,
        metricLegend: [],
        canEvaluate: null
        // metricList: [false, false, false, false, false]
    };
    public operandOriginalValue = null;
    public filteredDataItemsIndex = null;

    public metricArray = [];
    public math: any;

    public filteredDataItems = [];
    public availableDataItems = [];
    public referenceData: any;
    public newMetric = false;

    public idList: {
        limited: {
            value: string,
            action?()
        }[],
        readonly: {
            value: string,
            action?()
        }[]
    };

    public restrictionsGuid: string;


    public updateEvent: Subject<any> = new Subject<any>();
    @ViewChild('formulaBuilder') formulaBuilder: ElementRef;

    @Output() addClosed = new EventEmitter();
    form: any;
    selectDisabled: boolean;

    private customValidator = {
        metricName: [Validators.required, this.riskToleranceService.regexPatternValidator(),
            this.uniqueNameValidator(this.riskToleranceService)],
        details: [Validators.required],
        consequence: [Validators.required, this.riskToleranceService.regexPatternValidator()],
        scoreIndicator: [Validators.required],
        metricFormula: [Validators.required]
    };

    constructor(private riskToleranceService: RiskToleranceService, private fb: FormBuilder, private modalService: ModalService) {super()}

    ngOnInit() {
        const config = {};
        this.math = create(all, config);
        
        if (this.metric) {
            this.model.metricName = this.metric.label;
            this.model.details = this.metric.definition;
            this.model.metricFormula = this.metric.formula;
            this.model.consequence = this.metric.consequence;
            this.model.scoreIndicator = this.metric.scoreIndicator;
            // this.model.metricDirection = this.metric.
        } else {
            this.metric = <MetricVm>{};
            this.metric.id = this.riskToleranceService.getNewMetricId();
            this.metric.isCustom = true;
            // this.metric.periods = [];
            this.metric.lowDeviation = this.riskToleranceService.refData.deviationRef.lowDeviation / 100;
            this.metric.highDeviation = this.riskToleranceService.refData.deviationRef.hIghDeviation / 100;
            this.metric.lowOutlier = this.riskToleranceService.refData.deviationRef.lowOutlier / 100;
            this.metric.highOutlier = this.riskToleranceService.refData.deviationRef.highOutlier / 100;
            this.newMetric = true;
        }
        this.availableDataItems = this.riskToleranceService.refData.templateDataItems;
        this.model.parsedMetricFormula = this.revertFormula(this.model.metricFormula);
        this.priorities = this.riskToleranceService.riskTolerance.priorities.toDictionary('id');
        this.prioritiesArray = this.riskToleranceService.riskTolerance.priorities;
        this.metrics = this.riskToleranceService.riskTolerance.metrics.toDictionary('id');
        this.defaultMetrics = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.metrics.filter(c => !c.isCustom)));
        this.customMetrics = JSON.parse(JSON.stringify(this.riskToleranceService.riskTolerance.metrics.filter(c => c.isCustom)));
        this.referenceData = this.riskToleranceService.refData;
        this.form = Object.toFormGroup(this.model, this.fb, this.customValidator);
        this.metricArray = this.riskToleranceService.tokenize(this.model.metricFormula,
            { operators: /[+-/*()^]/, operands: /([a-z]+)/, numbers: /(\d+)/ }, 'invalid');
        this.setMetricLegend(this.model.parsedMetricFormula);
        if(!this.metric.isCustom){
        this.setupUserRestrictions();
        }

    }
    
  setupUserRestrictions() {
    this.idList.limited = [
    {
      value: 'details',      
    },
    {
      value: 'metricNameId'
    },   
   
    {
      value: 'metricFormulaField'
    },
    {
      value: 'consequenceId'
    },
    {
      value: 'scoreIndicatorId'
    },

    {
        value: 'validateFormulaButton'
    },
    {
        value: 'metricLegendContainerId'
    },
    {
        value: 'saveButtonId',
        action: () => {
            
            this.myModalService= this.modalService;
            this.showModal(`saveButtonId`);            
    }
    },
    {
        value:'metricModalHeader',
        action: () => {
            
                this.myModalService= this.modalService;
                this.showModal(`metricModalHeader`);            
        }
    }
    ];
    this.restrictionsGuid = RISKTOLERANCECONSTANTS.EditBuiltinMetrics.restrictionsGuid
  }

    close() {
        this.riskToleranceService.showNavigation = true;
        this.addClosed.emit(null);
    }

    cancel() {
        this.riskToleranceService.showNavigation = true;
        this.addClosed.emit(null);
    }

    ok() {
        this.setMetricDataItems();
        const model = this.form.getRawValue();
        this.sanitizeInput(model);

        if (this.form.valid && this.model.canEvaluate) {

            this.setMetricDataItems();

            this.metric.label = model.metricName;
            this.metric.definition = model.details;
            this.metric.formula = this.model.metricFormula;
            this.metric.consequence = model.consequence;
            this.metric.scoreIndicator = model.scoreIndicator;

            this.riskToleranceService.showNavigation = true;
            if (!this.newMetric) {
                this.riskToleranceService.metricNames.find(c => c.id === this.metric.id).label = this.metric.label;
            }
            this.addClosed.emit(this.newMetric ? this.metric : null);
        } else {
            this.form.controls.metricName.markAsTouched();
            this.form.controls.details.markAsTouched();
            this.form.controls.metricFormula.markAsTouched();
            this.form.controls.parsedMetricFormula.markAsTouched();
            this.form.controls.consequence.markAsTouched();
            this.form.controls.scoreIndicator.markAsTouched();
        }
    }

    closePopup() {
        this.filteredDataItemsIndex = null;
        this.filteredDataItems = [];
    }

    clear(operandId) {
        this.form.controls.metricLegend.controls[operandId].controls.bindValue.patchValue(this.operandOriginalValue, false);
    }

    getOriginalValue(operandId) {
        this.operandOriginalValue = this.form.controls.metricLegend.controls[operandId].controls.bindValue.value;
    }


    onKeyup(e, index?, filter?) {
        if (this.updateEvent.observers.length === 0) {
            this.updateEvent
                .pipe(debounceTime(300))
                .subscribe(update => {
                    if (update.filter) {
                        this.filterDataItems(update.index);
                    }
                });
        }
        this.updateEvent.next({ e, filter, index });
    }

    checkMetricFormulaForChange(e) {
        const text = document.getElementById('metricFormulaField').innerText.toLowerCase();
        document.getElementById('metricFormulaField').innerText = text;
        this.setMetricLegend(text);
    }

    setMetricLegend(text) {
        this.model.parsedMetricFormula = text;
        const metricArray = this.riskToleranceService.tokenize(text, { operands: /([a-z]+)/ }, 'invalid');
        const metricLegend = metricArray.filter(c => c.type !== 'invalid').map(c => c.itemValue);
        this.model.metricLegend = this.form.controls.metricLegend.getRawValue();
        this.model.metricLegend.forEach(c => {
            c.isVisible = false;
            if (metricLegend.find((d, i) => d === c.bind)) {
                c.isVisible = true;
            }
        });
        metricLegend.forEach(c => {
            if (!this.model.metricLegend.find(d => d.bind === c)) {
                this.model.metricLegend.push({
                    bind: c,
                    bindValue: null,
                    isVisible: true,
                    dataItem: null
                });
            }
        });
        this.form.controls.metricLegend = Object.toFormGroup(this.model.metricLegend, this.fb, this.customValidator);
    }

    filterDataItems(index) {
        this.filteredDataItemsIndex = null;
        this.filteredDataItems = [];
        const text = this.form.controls.metricLegend.value[index].bindValue;
        if (text && text.length > 3) {
            this.filteredDataItems = this.availableDataItems
                .filter(c => c.name.trim().toLowerCase().indexOf(text.trim().toLowerCase()) >= 0);
            if (this.filteredDataItems.length) {
                this.filteredDataItemsIndex = index;
            }
        }
    }

    updateFormula(item, dropdownValue) {
        this.filteredDataItemsIndex = null;
        this.filteredDataItems = [];
        item.bindValue = dropdownValue.name;
        this.form.controls.metricLegend.controls.forEach(c => {
            if (c.value.bind === item.bind) {
                c.controls.bindValue.setValue(dropdownValue.name);
                c.controls.dataItem.setValue(dropdownValue.id);
            }
        });
    }

    setMetricDataItems() {
        this.model.metricFormula = this.model.parsedMetricFormula;
        let metric = this.model.parsedMetricFormula;
        const metricLegend = this.form.controls.metricLegend.getRawValue();
        metricLegend.sort((a, b) => a.bind.localeCompare(b.bind) > 0 ? -1 : 1);
        metricLegend.forEach(c => {
            if (c.isVisible) {
                metric = metric.split(c.bind).join(c.dataItem ? `1` : null);
                this.model.metricFormula = this.model.metricFormula.split(c.bind).join(`{${c.dataItem}}`);
            }
        });
        this.model.canEvaluate = false;
        try {
            if (metric) {
                this.math.evaluate(metric);
                this.model.canEvaluate = true;
                this.form.controls.metricFormula.setValue(this.model.metricFormula);
            }
        } catch (e) {
            // empty block
        }
    }

    revertFormula(text) {
        const metricArray = this.riskToleranceService.tokenize(text, { operands: /{\d+}/ }, 'invalid').filter(c => c.type !== 'invalid');
        metricArray.forEach((c, i) => {
            const token = +c.token.replace('{', '').replace('}', '');
            const dataItem = this.availableDataItems.find(d => d.id === token);
            text = text.split(c.token).join(String.fromCharCode(97 + i));
            if (!dataItem) {
                this.model.metricLegend.push({
                    bind: String.fromCharCode(97 + i),
                    bindValue: 'Invalid dataItem used',
                    dataItem: -1,
                    isVisible: true
                });
            } else {
                this.model.metricLegend.push({
                    bind: String.fromCharCode(97 + i),
                    bindValue: dataItem.name,
                    dataItem: dataItem.id,
                    isVisible: true
                });
            }
        });
        return text;
    }

    uniqueNameValidator(riskToleranceService: RiskToleranceService) {
        return (control: AbstractControl): { [key: string]: boolean } | null => {

            if (riskToleranceService.metricNames.find(c => c.label === control.value && c.id !== this.metric.id)) {
                return { 'notUniqueName': true };
            }
            return null;
        };
    }

    openDeviationsModal() {


        const confirmConfig = {
            class: 'modal-dialog modal-lg',
            data: this.metric
        };
        this.modalService.confirmWithComponent(PercentilesComponent, null,confirmConfig).then((res) => {
            if (res.result === true && res.data) {
                // this.financialService.loadScenario(res.data.selectedStatementId);
            }
        });
    }

    sanitizeInput(model) {
        model.details = this.riskToleranceService.sanitizeInput(model.details);
        this.form.controls.details.setValue(model.details);
    }

}
