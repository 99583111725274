import { Routes } from '@angular/router';
import { NavigationAction, NavigationType } from '@wtw/platform/interfaces';
import { FinancialsComponent } from './pages/financials/financials.component';
import { RiskToleranceComponent } from './pages/riskTolerance/riskTolerance.component';
import { RoutesService } from './services/RoutesService';
import { RISKTOLERANCECONSTANTS } from './shared/constants';
import { SubRoleFeatureGuard } from '@wtw/platform/services/guards/SubRoleFeatureGuard';
function validateCurrentStage(stage: string,currentPage:string) {
    if(stage === null || stage === undefined) { return false; }
  return  stage.trim().toLowerCase().includes(currentPage.toLowerCase());
}
function validateSubrole() {
    if(Object.keys(RoutesService.permissions).length>0) { return true; }
  return  false;
}
export const RUN_PAGES: Routes = [
    {
        path: 'financials',
        component: FinancialsComponent,  
        canActivate: [SubRoleFeatureGuard],     
        data: {
            displayTitle: 'Financials',
            menuIconImg: 'icons8-brief.svg',
            onForwardNavigation: <NavigationAction>{ type: NavigationType.persist },
            runProgress: true,
            customNavigation: <boolean>true,
            canActivate: (stage: string, activePages: { [key: string]: any }) => {
                
                
                return true;
                
            },
            isVisible: () => {
                return true;
            },
            
        }
    }
    , {
        path: 'riskTolerance',
        component: RiskToleranceComponent,  
        canActivate: [SubRoleFeatureGuard],      
        data: {
            displayTitle: 'RiskTolerance',
            menuIconImg: 'icons8-strategy.svg',
            'runProgress': false,
            'isResultsPage': true,
            'showRunNavigation': true,
            customNavigation: <boolean>true,
            canActivate: (stage: string, activePages: { [key: string]: any }) => {
                
                const currentRun = RoutesService.currentRun;
                //to support existing runs
                if(validateCurrentStage(stage,'financials;riskTolerance')){
                    return true;
                }
                else if (currentRun?.riskTolerance && !currentRun.riskTolerance.selectedPeerGroup) {
                    return false;
                }
                else if (currentRun?.riskTolerance?.selectedPeerGroup && currentRun.enableRiskTolerance!=undefined) {
                    return currentRun.enableRiskTolerance;
                }
                if (activePages?.['riskTolerance']?.canNavigate) {
                    return true;
                }
                return false;
            },
            isVisible: () => {
                return true;
            } ,
                    
        }
    }
];