

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FinancialService } from 'app/services/financial.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService, UserService } from '@wtw/platform/services';
import { BaseUserRestrictionsComponent } from '@wtw/platform/components/sub-role/base-user-restrictions.component';
import { RISKTOLERANCECONSTANTS } from 'app/shared/constants';

@Component({
    selector: 'load-financial-statements',
    templateUrl: '../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/loadFinancials/loadFinancials.component.html',
    styleUrls: ['../../../../../../node_modules/@acl/rtc-components/src/lib/pages/financials/financialInputs/loadFinancials/loadFinancials.component.scss']
})

export class LoadFinancialsComponent extends BaseUserRestrictionsComponent implements OnInit {

    public saveFinanacialModel = {
        saveName: '',
        selectedStatementId: ''
    };

    public dataListHeader = {
        scenarioName: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return a.localeCompare(b);
            }
        },
        lastModified: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return moment(a).unix() - moment(b).unix();
            }
        },
        periodType: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return (a === b) ? 0 : a < b ? -1 : 1;
            }
        },
        reportingTemplateType: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return (a === b) ? 0 : a < b ? -1 : 1;
            }
        },
        projectionType: {
            icon: 'icon-combo-arrow',
            sortOrder: 0,
            sortFuntion(a, b) {
                return (a === b) ? 0 : a < b ? -1 : 1;
            }
        }
    };

    public translationKey = 'RTC.FINANCIALS.LOADMODAL';

    public translation = {};
    public dataList: {
        finScenarioId: number;
        scenarioName: string;
        currentlySelected: boolean;
        lastModified: string;
        periodType: number;
        reportingTemplateType: number;
        projectionType: number;
        projectionValue: any;
        isDefault: boolean;
    }[];
    public form: FormGroup;
    public active: boolean;
    public onClose: Subject<boolean>;
    public data: any = {};
    public mandatoryMissing: boolean;
    public financialStatementRestrictionGuid;
    public financialStatementRestrictionClassList = {
        limited: [],
        readonly: []
    }
    public financialStatementRestrictionIdList = {
        limited: [],
        readonly: []
    }
    public myModalService: ModalService;
    selectedStatementId: number | null = null;
    private validators = {
        selectedStatementId: [Validators.required]
    };
    constructor(private financialService: FinancialService, private fb: FormBuilder, 
        public _bsModalRef: BsModalRef, private modalService: ModalService) {
        super();
    }

    ngOnInit() {
        this.onClose = new Subject();
        this.dataList = this.financialService.savedStatements;
        this.dataList.forEach(c => {
            (c as any).scenarioNameShorter = c.scenarioName.length > 20 ? c.scenarioName.substring(0, 20) + '...' : c.scenarioName;
        });
        this.form = Object.toFormGroup(this.saveFinanacialModel, this.fb, this.validators);
        this.financialStatementRestrictionGuid = RISKTOLERANCECONSTANTS.ViewFinancialStatementRestrictions.restrictionsGuid;
        this.financialStatementRestrictionIdList.limited = [
        {
            value: `financial-statement-restricted-btn`,
            action:()=>{
                this.myModalService= this.modalService;
                this.showModal(`financial-statement-restricted-btn`);
            }
        }];
        
       // Assign readonly before the iteration and push the additional item
        this.financialStatementRestrictionIdList.readonly = [
            ...this.financialStatementRestrictionIdList.limited,
            {
                value: `save-financial-info`,
                action: () => {
                    this.myModalService = this.modalService;
                    this.showModal(`save-financial-info`);
                }
            }
        ];

        this.dataList.forEach(item => {
            const lockValue = `${item.finScenarioId}div`;
            //readonly User can't select the radio button
            this.financialStatementRestrictionIdList.readonly.push({
                value: lockValue,
                action: () => {
                    this.myModalService = this.modalService;
                    this.showModal(lockValue);
                }
            });
            //limited User can't select the radio button
            if(!((item.periodType == 1 || item.periodType == 2) && (item.reportingTemplateType == 50 && item.isDefault == true))){
                this.financialStatementRestrictionIdList.limited.push({
                    value: lockValue,
                    action: () => {
                        this.myModalService = this.modalService;
                        this.showModal(lockValue);
                    }
                });
            }
        });
       

    }
    sorHeader(item, key) {
        const order = item.sortOrder || -1;
        Object.keys(this.dataListHeader).forEach(c => {
            this.dataListHeader[c].sortOrder = 0;
            this.dataListHeader[c].icon = 'icon-combo-arrow';
        });
        item.sortOrder = order * -1;
        if (item.sortOrder === 1) {
            item.icon = 'icon-up_arrow';
        } else {
            item.icon = 'icon-down_arrow';
        }
        this.orderList(item, key);
    }

    orderList(item, key) {
        this.dataList = this.financialService.savedStatements
            .sort((a, b) => item.sortFuntion(a[key], b[key]) * item.sortOrder);
    }

    public close() {
        this.hide(null);
    }

    public ok() {
        this.removeParentOnClick();
        this.mandatoryMissing = false;
        if (this.data && this.data.validateMandatory) {
            this.mandatoryMissing = true;
            if (this.financialService.validStatements.find(c => c.finScenarioId === this.form.controls.selectedStatementId.value)) {
                this.mandatoryMissing = false;
            }
        }

        if (!this.form.valid || this.mandatoryMissing) {
            return;
        }
        this.hide(true);
    }

    public cancel() {
        this.removeParentOnClick();
        this.hide(false);
    }

    public show() {
        this.active = true;
    }

    isButtonDisabled(): boolean {
        var hasDisabledIds = this.financialStatementRestrictionIdList && this.financialStatementRestrictionIdList.limited.length > 0;
        var tds = (document.getElementsByClassName('financial-statement-restricted') as any);    
        var anyDisabled = Array.from(tds).some(td => (td as any).disabled);
        var noRadioSelected = this.selectedStatementId === null;       
        var isDisabled = hasDisabledIds && noRadioSelected && anyDisabled;
        if(!isDisabled){
            this.removeParentOnClick();
        }   
        return isDisabled;
    }

    private hide(nextVal?: boolean) {
        this.active = false;
        this.data = this.form.getRawValue();
        this.onClose.next(nextVal);
        this._bsModalRef.hide();
    }

    private removeParentOnClick(){
        var openFinancialStatementBtn = document.getElementById("financial-statement-restricted-btn");
        if(openFinancialStatementBtn && openFinancialStatementBtn.parentNode){
            (openFinancialStatementBtn.parentNode as any).onclick = function () {
        };
     }
    }
}
