

import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { RiskToleranceService } from 'app/services/riskTolerance.service';
import { ModalService } from '@wtw/platform/services';
import { RiskAppetiteModalComponent } from '../riskAppetiteModal/riskAppetiteModal.component';
import { SummaryPriorityModalComponent } from '../summaryPriorityModal/summaryPriorityModal.component';
import { ShortNumberFormatPipe } from 'app/pipes/ShortNumberFormatExtenderPipe';
import { LoadFinancialsComponent } from 'app/pages/financials/financialInputs/loadFinancials/loadFinancials.component';
import { IButtonConfig, IStandardModalConfig } from '@wtw/platform/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { PriorityConfiguration } from 'app/api/dtos';
import { PdfModalComponent } from '../pdfModal/pdfModal.component';
import { UrlHelperService } from '@wtw/platform/services/url-helper.service';
import { Base, SpinnerDirective  } from '@wtw/toolkit';
import { DownloadService } from 'app/services/download.service';
import { BaseUserRestrictionsComponent } from '@wtw/platform/components/sub-role/base-user-restrictions.component';
import { ReadOnlyAccessModalComponent } from '@wtw/platform/components/sub-role/read-only-access-modal/read-only.component';
import { RISKTOLERANCECONSTANTS } from 'app/shared/constants';
@Component({
    selector: 'executive-summary',
    templateUrl: '../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/executiveSummary/executiveSummary.component.html',
    styleUrls: ['../../../../../node_modules/@acl/rtc-components/src/lib/pages/riskTolerance/executiveSummary/executiveSummary.component.scss']
})

export class ExecutiveSummaryComponent extends BaseUserRestrictionsComponent implements OnInit {
    public translationKey = 'RTC.RISKTOLERANCE.EXECUTIVESUMMARY';
    public translation: any = {

    };
    public priorities = {};
    public metrics = {};
    public riskAppetite = {
        0: {
            TEXT: 'Aggressive',
            class: 'btn-danger'
        },
        1: {
            TEXT: 'Neutral',
            class: 'btn-warning'
        },
        2: {
            TEXT: 'Conservative',
            class: 'btn-success'
        }
    };
    public model;
    public summaryModel;
    public detailsModel;
    public statement;
    public openEditPeer: boolean;
    public missingData = {
        rbc: false,
        rtp: false
    };
    public isCustomMetric = false;
    public showPDFSpinner = false;
    public myModalService: ModalService;
    public riskToleranceGuid = '';
    public executiveSummaryList = {
        limited: [],
        readonly: []
    }
    public financialStatementRestrictionGuid;
    public financialStatementRestrictionClassList = {
        limited: [],
        readonly: [],
        noaccess:[]
    }
    public financialStatementRestrictionIdList = {
        limited: [],
        readonly: [],
        noaccess:[]
    }

    private reInit = false;
    private platformUrl: any;
    private baseUserRest: BaseUserRestrictionsComponent;
    @ViewChildren(SpinnerDirective) set _spinners(spinners: QueryList<SpinnerDirective>) {
        if (spinners)
          spinners.forEach(element => element.showSpinner());
      }
      
    constructor(private riskToleranceService: RiskToleranceService,
        private modalService: ModalService, private shortNumberFormat: ShortNumberFormatPipe,
        private _translateService: TranslateService,
        private downloadService: DownloadService) {
        super();
        this.baseUserRest = new BaseUserRestrictionsComponent();

    }

    ngOnInit() {
        this.load();
        this._subscriptions.push(this.riskToleranceService.reloadCompleted.subscribe(_ => {
            this.load();
        })); 
        this.riskToleranceGuid = RISKTOLERANCECONSTANTS.RiskToleranceComponent.restrictionsGuid;
        this.executiveSummaryList= {
            limited: [],
            readonly: []
        };  
         
        this.executiveSummaryList.readonly.push({
            value:`modelPeer`,
            action:() =>{
                this.myModalService= this.modalService;
                this.showModal(`modelPeer`);
            },        
        }
    );

    this.financialStatementRestrictionGuid = RISKTOLERANCECONSTANTS.ViewFinancialStatementRestrictions.restrictionsGuid;
    this.financialStatementRestrictionIdList.readonly = [
    {
        value: `savedStatements`,
        action:()=>{
            this.myModalService= this.modalService;
            this.showModal(`savedStatements`);
        }
    }];
    this.financialStatementRestrictionIdList.noaccess=this.financialStatementRestrictionIdList.readonly;

    }

    load() {
        this.reInit = false;
        this.model = this.riskToleranceService.riskTolerance;
        this.isCustomMetric = false;
        if (this.riskToleranceService && this.riskToleranceService.riskTolerance &&
            this.riskToleranceService.riskTolerance.customAnalysis &&
            Array.isArray(this.riskToleranceService.riskTolerance.customAnalysis)) {
            this.isCustomMetric = this.riskToleranceService.riskTolerance.customAnalysis.length > 0;
        }
        this.metrics = this.model.metrics.toDictionary('id');
        this.priorities = JSON.parse(JSON.stringify(this.model.priorities.filter(c => !c.isDeleted))).toDictionary('id');
        this.setSummaryModel();
        this.detailsModel = {
            name: this.riskToleranceService.run.generalClientInfo.clientName,
            peer: [...this.riskToleranceService.peerRefData.peerGroups, ...this.riskToleranceService.peerRefData.globalPeerGroups]
                .find(c => +c.peerGroupId === +this.riskToleranceService.run.riskTolerance.selectedPeerGroup).peerGroupName
        };
        this.missingData.rbc = Object.values(this.getLossThreshold(this.model.riskBearingCapacity, 'riskBearingCapacity'))
            .find(c => c === 'N/A') !== undefined;
        this.missingData.rtp = Object.values(this.getLossThreshold(this.model.riskToleranceProperty, 'riskToleranceProperty'))
            .find(c => c === 'N/A') !== undefined;

        this.platformUrl = UrlHelperService.getPlatformUrl();
    }

    getLossThreshold(priorityConfiguration: PriorityConfiguration, type) {
        const priority = priorityConfiguration.priorityId;
        if (isNaN(priority) || priority < 1 || !this.priorities[priority].summaryValues) {
            return {
                value: null,
                min: null,
                max: null
            };
        }
        let overwriteVlaue = 0;
        if (type === 'riskBearingCapacity') {
            overwriteVlaue = this.riskToleranceService.riskTolerance.riskBearingCapacity.deviation;
        } else if (type === 'riskToleranceProperty') {
            overwriteVlaue = this.riskToleranceService.riskTolerance.riskToleranceProperty.deviation;
        }
        return {
            value: this.shortNumberFormat.transform(overwriteVlaue || this.priorities[priority].summaryValues.suggested),
            min: this.shortNumberFormat.transform(this.priorities[priority].summaryValues.low),
            max: this.shortNumberFormat.transform(this.priorities[priority].summaryValues.high)
        };
    }

    changeRiskAppetite() {
        const confirmConfig = {
            class: 'modal-dialog modal-lg'
        };
        this.modalService.confirmWithComponent(RiskAppetiteModalComponent, null, confirmConfig).then((res) => {
            if (res.result === true && res.data) {
                this.riskToleranceService.saveRiskAppetite(res.data);
                this.setSummaryModel();
            }
        });
    }

    setSummaryModel() {       
        this.summaryModel = {
            riskTolerance: {
                name: this.priorities[this.model.riskToleranceProperty.priorityId] ?
                    this.priorities[this.model.riskToleranceProperty.priorityId].name : null,
                lossThreshold: this.getLossThreshold(this.model.riskToleranceProperty, 'riskToleranceProperty')

            },
            riskBearingCapacity: {
                name: this.priorities[this.model.riskBearingCapacity.priorityId] ?
                    this.priorities[this.model.riskBearingCapacity.priorityId].name : null,
                lossThreshold: this.getLossThreshold(this.model.riskBearingCapacity, 'riskBearingCapacity')
            }

        };        
    }



    openSummaryPriorityModal(modalSelected: number) {
        const confirmConfig = {
            data: modalSelected
        };
        const otherConfigOptions = {
            class:'modal-dialog modal-lg'
           };
        this.modalService.confirmWithComponent(SummaryPriorityModalComponent, confirmConfig, otherConfigOptions).then((res) => {
            if (res.result === true && res.data) {
                this.riskToleranceService.saveSummaryPriority(modalSelected, res.data);
            }
        });
    }
    openPdfModal() {
        event.stopPropagation();
        const confirmConfig = {
            class: 'modal-dialog modal-lg'
        };
        
        if(!this.showPDFSpinner){
        this.modalService.confirmWithComponent(PdfModalComponent, null,confirmConfig).then(res => {
            if (res.result === true && res.data && res.data !== undefined) {
                this.showPDFSpinner = true;
                this.downloadService.downloadPDF(res.data)
                .subscribe(blob => {
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `report.pdf`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }).add(() => {
                        this.showPDFSpinner = false;
                    });
            }
        });
    }
    }
    
    openSavedStatements() {
        const confirmConfig = {
            data: {
                validateMandatory: true
            }
        };

        const otherConfigOptions = {
            class:'modal-dialog modal-lg'
           };

        this.modalService.confirmWithComponent(LoadFinancialsComponent, confirmConfig, otherConfigOptions).then((res) => {
            if (res.result === true && res.data && res.data.selectedStatementId) {
                if (this.riskToleranceService.run.financialInput.selectedScenarioId !== res.data.selectedStatementId) {
                    this.changeStatement(res.data.selectedStatementId);
                }               
            }
        });
    }

    changeStatement(statementId) {
        const yesConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant(`${this.translationKey}.CHANGESCENARIO.LOAD`),
            cssClass: 'btn btn-primary'
        };

        const cancelConfig = <IButtonConfig>{
            display: true,
            text: this._translateService.instant('GLOBAL.BTN.CANCEL')
        };

        const confirmConfig = <IStandardModalConfig>{
            title: this._translateService.instant(`${this.translationKey}.CHANGESCENARIO.TITLE`),
            withHeader: true,
            message: this._translateService
                .instant(`${this.translationKey}.CHANGESCENARIO.TEXT`),
            yesButton: yesConfig,
            noButton: cancelConfig
        };
        this.modalService.confirm(confirmConfig).then(
            (c) => {
                if (c) {
                    this.riskToleranceService.run.financialInput.selectedScenarioId = statementId;
                    this.riskToleranceService.reloadInProgress = true;
                    this.reInit = true;
                    this.riskToleranceService.calltrigger('calc_peer_analysis').subscribe();
                } else {
                    this.openSavedStatements();
                }
            }
        );
    }

    get statementName() {
        if (this.riskToleranceService.financialStatement) {
            return this.riskToleranceService.financialStatement.finScenario.scenarioName;
        }
        return '';
    }

    get loading() {
        if (this.reInit && !this.riskToleranceService.reloadInProgress) {
            this.load();
        }
        return this.riskToleranceService.reloadInProgress;
    }

    openPeerGroup() {
        this.riskToleranceService.showNavigation = false;
        this.riskToleranceService.scrollEnabled = false;
        this.openEditPeer = true;
    }

    closePeer() {
        this.riskToleranceService.showNavigation = true;
        this.riskToleranceService.scrollEnabled = true;
        this.load();
        this.openEditPeer = false;
    }
}
